<template>
  <div id="page">
    <PageHeader
      :pageTitle="title + ' - ' + pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row>
      <v-col cols="8">
        <DataTable
          @tableAction="emittedAction"
          :items="items"
          :headers="headers"
          :itemActions="{
            edit: true,
            trash: true,
          }"
          :disableSearch="true"
          :preloader="preloader"
        />

        <div class="caption mt-4">
          <v-icon color="indigo" small> mdi-message-plus </v-icon> = Erhält
          Hinweis bei Lead<br />
          <v-icon color="indigo" small> mdi-message-cog </v-icon> = Erhält
          technische Hinweise<br />
          <v-icon color="indigo" small> mdi-message-text </v-icon> = Erhält
          Newsletter<br />

          <v-icon color="green" small> mdi-crown </v-icon> = CEO<br />
          <v-icon color="green" small> mdi-account-tie-voice </v-icon> =
          Entscheider<br />

          <v-icon color="pink" small> mdi-hand-heart </v-icon> = Inhaltlich
          verantwortlich<br />
          <v-icon color="pink" small> mdi-security </v-icon> =
          Datenschutzbeauftragter<br />
        </div>
      </v-col>

      <v-col cols="4">
        <Subnavigation
          root="/csm/company-manager/:id"
          :pageTitle="pageTitle"
        ></Subnavigation>
      </v-col>
    </v-row>

    <v-btn fixed dark fab bottom right color="primary" @click="addItem">
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <div v-if="createItem">
      <CreatePerson
        :trigger="createItem"
        @closeModal="createItem = false"
        @emit="emittedAction"
      />
    </div>

    <div v-if="editItem && items[itemIndex].id">
      <UpdatePerson
        :trigger="editItem"
        :itemData="itemData"
        @closeModal="editItem = false"
      ></UpdatePerson>
    </div>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import PageHeader from "@/components/ui/PageHeader.vue";
import Subnavigation from "@/components/ui/Subnavigation.vue";
import DataTable from "@/components/ui/DataTable.vue";
import UpdatePerson from "@/components/companies/contactpersons/UpdatePerson.vue";
import CreatePerson from "@/components/companies/contactpersons/CreatePerson.vue";
export default {
  name: "Contactpersons",

  components: {
    PageHeader,
    Subnavigation,
    DataTable,
    UpdatePerson,
    CreatePerson,
  },

  data() {
    return {
      pageTitle: "Kontaktpersonen",
      pageDescription: "Kontaktpersonen der Firma",
      title: "",
      edit: true,
      trash: true,
      copy: false,
      search: "",
      headers: [],
      items: [],
      preloader: false,
      createItem: false,
      editItem: false,
      itemId: 0, // id des Items, welches geklickt wurde
      itemIndex: 0, // index des Items, welches geklickt wurde
      itemData: null, // Daten des Items, welches geklickt wurde
    };
  },

  created: function () {
    this.getData(); // call it immediatly
  },

  methods: {
    async getData() {
      this.preloader = true;

      let response = await this.getRequest(
        "companies/" + this.$route.params.id + "/contactPersons"
      );
      this.preloader = false;

      if (response) {
        this.headers = [
          { text: "Rollen", value: "cpType", sortable: false },
          { text: "Anrede", value: "gender", sortable: false },
          { text: "Vorname", value: "firstName", sortable: false },
          { text: "Nachname", value: "lastName", sortable: false },
          { text: "E-Mail", value: "email", sortable: false },
          { text: "", value: "actions", sortable: false },
        ];

        this.items = response.data.data;

        /* Fetching Companyname for Headline */
        /* USEING REUSEABLE MIXIN METHOD */
        response = await this.getRequest("companies/" + this.$route.params.id); // await data from mixin
        this.title = response.data.data[0].name;
      }
    },

    emittedAction(value) {
      if (value.action === "editItem") {
        this.itemId = value.itemId;
        this.itemIndex = value.itemIndex;
        this.itemData = this.items[value.itemIndex];
        this.editItem = true;
      } else if (value.action === "createdItem") {
        this.items.push(value.newPost);
        this.createItem = false;
      } else if (value.action === "deleteItem") {
        this.deletePrompt(value.itemId); // reload
      } else if (value.action === "closeModal") {
        this.createItem = false;
        this.editItem = false;
      } else if (value.action === "itemPatched") {
        this.getData(); // reload
      }
    },

    addItem() {
      this.createItem = true;
    },

    async deletePrompt(id) {
      const userChoice = await this.$dialog.confirm({
        text: "Soll der Ansprechpartner wirklich gelöscht werden?",
        title: "Achtung!",
        waitForResult: true,
      });

      if (userChoice) {
        // okay
        /* USEING REUSEABLE MIXIN METHOD */
        this.preloader = true;
        let response = await this.deleteRequest(
          "companies/" + this.$route.params.id + "/contactPersons/" + id
        ); // await data from mixin
        this.preloader = false;

        if (response) {
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText = "Eintrag wurde gelöscht";
          this.$root.snackbarGlobal.errorMessage = "";
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;
          this.getData(); // reload
        }
      } else {
        // abort
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Vorgang abgebrochen!";
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },
  },

  mixins: [apiRequest],
};
</script>
