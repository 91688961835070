<template>
  <v-dialog v-model="trigger" persistent scrollable max-width="800px">
    <v-card>
      <v-app-bar flat color="primary">
        <v-toolbar-title class="title white--text pl-0">
          {{ this.itemData.firstName }} {{ this.itemData.lastName }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="white" icon @click="$emit('closeModal')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <Patcher
              :itemValue="itemData.gender"
              itemName="gender"
              itemLabel="Anrede"
              :hideLabel="false"
              :route="route"
              inputElement="select"
              inputType=""
              :selectOptions="selectOptions.gender"
              @emit="itemData.gender = $event.value"
            ></Patcher>

            <Patcher
              :itemValue="itemData.firstName"
              itemName="firstName"
              itemLabel="Vorname"
              :hideLabel="false"
              :route="route"
              inputElement="input"
              inputType="text"
              @emit="itemData.firstName = $event.value"
            ></Patcher>

            <Patcher
              :itemValue="itemData.lastName"
              itemName="lastName"
              itemLabel="Nachname"
              :hideLabel="false"
              :route="route"
              inputElement="input"
              inputType="text"
              @emit="itemData.lastName = $event.value"
            ></Patcher>

            <Patcher
              :itemValue="itemData.phone"
              itemName="phone"
              itemLabel="Telefon"
              :hideLabel="false"
              :route="route"
              inputElement="input"
              inputType="text"
              @emit="itemData.phone = $event.value"
            ></Patcher>

            <Patcher
              :itemValue="itemData.email"
              itemName="email"
              itemLabel="E-Mail"
              :hideLabel="false"
              :route="route"
              inputElement="input"
              inputType="text"
              @emit="itemData.email = $event.value"
            ></Patcher>

            <Patcher
              :itemValue="itemData.street"
              itemName="street"
              itemLabel="Straße"
              :hideLabel="false"
              :route="route"
              inputElement="input"
              inputType="text"
              @emit="itemData.street = $event.value"
            ></Patcher>

            <Patcher
              :itemValue="itemData.streetNumber"
              itemName="streetNumber"
              itemLabel="Hausnummer"
              :hideLabel="false"
              :route="route"
              inputElement="input"
              inputType="text"
              @emit="itemData.streetNumber = $event.value"
            ></Patcher>

            <Patcher
              :itemValue="itemData.zipCode"
              itemName="zipCode"
              itemLabel="Postleitzahl"
              :hideLabel="false"
              :route="route"
              inputElement="input"
              inputType="text"
              @emit="itemData.zipCode = $event.value"
            ></Patcher>

            <Patcher
              :itemValue="itemData.town"
              itemName="town"
              itemLabel="Ort"
              :hideLabel="false"
              :route="route"
              inputElement="input"
              inputType="text"
              @emit="itemData.town = $event.value"
            ></Patcher>
          </v-col>

          <v-col cols="12">
            <h2>Benachrichtigungen</h2>
          </v-col>

          <v-col cols="12">
            <Patcher
              :itemValue="itemData.notificationWhenLead"
              itemName="notificationWhenLead"
              itemLabel="Leadbenachrichtigung"
              :hideLabel="true"
              :route="route"
              inputElement="switch"
              inputType=""
              :selectOptions="selectOptions.yesNo"
              @emit="itemData.notificationWhenLead = $event.value"
            ></Patcher>

            <Patcher
              :itemValue="itemData.getsTechnicalNotifications"
              itemName="getsTechnicalNotifications"
              itemLabel="Erhält technische Hinweise"
              :hideLabel="true"
              :route="route"
              inputElement="switch"
              inputType=""
              :selectOptions="selectOptions.yesNo"
              @emit="itemData.getsTechnicalNotifications = $event.value"
            ></Patcher>

            <Patcher
              :itemValue="itemData.hasNewsletter"
              itemName="hasNewsletter"
              itemLabel="Erhält Newsletter"
              :hideLabel="true"
              :route="route"
              inputElement="switch"
              inputType=""
              :selectOptions="selectOptions.yesNo"
              @emit="itemData.hasNewsletter = $event.value"
            ></Patcher>
          </v-col>

          <v-col cols="12">
            <h2>Rolle in Firma</h2>
          </v-col>

          <v-col cols="12">
            <Patcher
              :itemValue="itemData.isDecider"
              itemName="isDecider"
              itemLabel="Entscheider"
              :hideLabel="true"
              :route="route"
              inputElement="switch"
              inputType=""
              :selectOptions="selectOptions.yesNo"
              @emit="itemData.isDecider = $event.value"
            ></Patcher>

            <Patcher
              :itemValue="itemData.isCeo"
              itemName="isCeo"
              itemLabel="CEO"
              :hideLabel="true"
              :route="route"
              inputElement="switch"
              inputType=""
              :selectOptions="selectOptions.yesNo"
              @emit="itemData.isCeo = $event.value"
            ></Patcher>
          </v-col>

          <v-col cols="12">
            <h2>Rechtliches</h2>
          </v-col>

          <v-col cols="12">
            <Patcher
              :itemValue="itemData.isDataProtectionOfficer"
              itemName="isDataProtectionOfficer"
              itemLabel="Datenschutzbeauftragter"
              :hideLabel="true"
              :route="route"
              inputElement="switch"
              inputType=""
              :selectOptions="selectOptions.yesNo"
              @emit="itemData.isDataProtectionOfficer = $event.value"
            ></Patcher>

            <Patcher
              :itemValue="itemData.isContentResponsibility"
              itemName="isContentResponsibility"
              itemLabel="Verantwortlich für die Inhalte"
              :hideLabel="true"
              :route="route"
              inputElement="switch"
              inputType=""
              :selectOptions="selectOptions.yesNo"
              @emit="itemData.isContentResponsibility = $event.value"
            ></Patcher>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Patcher from "@/components/ui/Patcher.vue";
export default {
  name: "UpdatePerson",

  components: {
    Patcher,
  },

  props: ["trigger", "itemData"],

  data() {
    return {
      title: "",
      route:
        "companies/" +
        this.$route.params.id +
        "/contactPersons/" +
        this.itemData.id,
      selectOptions: {
        gender: [
          { name: "Weiblich", value: "female" },
          { name: "Männlich", value: "male" },
          { name: "Divers", value: "diverse" },
        ],
        yesNo: [
          { name: "Nein", value: 0 },
          { name: "Ja", value: 1 },
        ],
      },
    };
  },
};
</script>
