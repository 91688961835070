<template>
  <v-dialog v-model="trigger" persistent scrollable max-width="800px">
    <v-card>
      <v-app-bar flat color="primary">
        <v-toolbar-title class="title white--text pl-0">
          Neue Kontaktperson anlegen
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="white" icon @click="$emit('closeModal')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>

      <v-card-text>
        <v-form id="form" ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12">
              <v-select
                class="align-self-center"
                v-model="gender"
                :items="genderItems"
                item-text="name"
                item-value="value"
                label="Geschlecht"
                :rules="[rules.required]"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Vorname"
                v-model="firstname"
                type="text"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Nachname"
                v-model="lastname"
                type="text"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                label="E-Mail"
                v-model="email"
                type="email"
                :rules="[rules.required, rules.email]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                label="Telefon"
                v-model="phone"
                type="tel"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="10">
              <v-text-field
                label="Straße"
                v-model="street"
                type="text"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="2">
              <v-text-field
                label="Hausnummer"
                v-model="streetnumber"
                type="text"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                label="Postleitzahl"
                v-model="zip"
                type="number"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="8">
              <v-text-field
                label="Ort"
                v-model="city"
                type="text"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <h2>Benachrichtigungen</h2>
            </v-col>

            <v-col cols="12">
              <v-switch
                v-model="notificationWhenLead"
                true-value="1"
                false-value="0"
                label="Hinweis bei Lead"
              ></v-switch>

              <v-switch
                v-model="getsTechnicalNotifications"
                true-value="1"
                false-value="0"
                label="Bekommt technische Hinweise"
              ></v-switch>

              <v-switch
                v-model="hasNewsletter"
                true-value="1"
                false-value="0"
                label="Bekommt Newsletter"
              ></v-switch>
            </v-col>

            <v-col cols="12">
              <h2>Rolle in Firma</h2>
            </v-col>

            <v-col cols="12">
              <v-switch
                v-model="isCeo"
                true-value="1"
                false-value="0"
                label="Geschäftsführer"
              ></v-switch>

              <v-switch
                v-model="isDecider"
                true-value="1"
                false-value="0"
                label="Entscheider"
              ></v-switch>
            </v-col>

            <v-col cols="12">
              <h2>Rechtliches</h2>
            </v-col>

            <v-col cols="12">
              <v-switch
                v-model="isDataProtectionOfficer"
                true-value="1"
                false-value="0"
                label="Datenschutzbeauftragter"
              ></v-switch>

              <v-switch
                v-model="isContentResponsibility"
                true-value="1"
                false-value="0"
                label="Inhaltlich Verantwortlicher"
              ></v-switch>
            </v-col>

            <v-col cols="12">
              <v-btn
                color="primary"
                class="white--text"
                @click="validate"
                :loading="preloader"
                :disabled="!valid"
              >
                Speichern
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
export default {
  name: "CreatePerson",
  props: ["trigger"],
  data() {
    return {
      route: "companies/" + this.$route.params.id + "/contactPersons",
      preloader: false,
      valid: false,
      gender: "",
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      street: "",
      streetnumber: "",
      zip: "",
      city: "",
      isCeo: 0,
      isDataProtectionOfficer: 0,
      isContentResponsibility: 0,
      isDecider: 0,
      notificationWhenLead: 0,
      getsTechnicalNotifications: 0,
      isCompanyRepresentative: 0,
      hasNewsletter: 0,
      genderItems: [
        { name: "Weiblich", value: "female" },
        { name: "Männlich", value: "male" },
        { name: "Divers", value: "diverse" },
      ],
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  methods: {
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.postData();
      }
    },

    async postData() {
      let formData = new FormData();
      formData.append("gender", this.gender);
      formData.append("firstName", this.firstname);
      formData.append("lastName", this.lastname);
      formData.append("email", this.email);
      formData.append("phone", this.phone);
      formData.append("street", this.street);
      formData.append("streetNumber", this.streetnumber);
      formData.append("zip", this.zip);
      formData.append("city", this.city);
      formData.append("isCeo", this.isCeo);
      formData.append("isDataProtectionOfficer", this.isDataProtectionOfficer);
      formData.append("isContentResponsibility", this.isContentResponsibility);
      formData.append("isDecider", this.isDecider);
      formData.append("notificationWhenLead", this.notificationWhenLead);
      formData.append(
        "getsTechnicalNotifications",
        this.getsTechnicalNotifications
      );
      formData.append("hasNewsletter", this.hasNewsletter);

      this.preloader = true;
      let response = await this.postRequest(this.route, "", formData);
      this.preloader = false;
      let newPost = response.data.data;
      this.$emit("emit", { action: "createdItem", newPost: newPost });

      this.$root.snackbarGlobal.snackbar = true;
      this.$root.snackbarGlobal.snackbarText =
        "Ein neuer Datensatz wurde angelegt.";
      this.$root.snackbarGlobal.snackbarColor = "primary";
      this.$root.snackbarGlobal.snackbarTimeout = 5000;
    },
  },
  mixins: [apiRequest],
};
</script>
